import React from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material/styles';

import { priceFormat, removeVietnameseTones } from '../../utils/string';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const HighlightedNotes = ({ notes, searchValue }) => {
  const formatedNotes = notes.toLowerCase();
  const formatedSearchValue = removeVietnameseTones(searchValue).toLowerCase();

  const index = formatedNotes.indexOf(formatedSearchValue);
  const length = formatedSearchValue.length;

  return (
    <>
      {notes.slice(0, index)}
      <span style={{ fontWeight: 'bold' }}>
        {notes.slice(index, index + length)}
      </span>
      {notes.slice(index + length)}
    </>
  );
};

const TransactionTable = ({ transactions, loading, searchValue, page, rowsPerPage, onPageChange, onRowsPerPageChange }) => {
  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table id='transaction-table'>
          <TableHead>
            <TableRow>
              <TableCell>Ngày</TableCell>
              <TableCell>Số Tiền</TableCell>
              <TableCell>Nội Dung</TableCell>
              <TableCell>Mã GD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (transactions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []).map((row) => (
              <StyledTableRow
                key={row.id || (row.date + (row.code || '') + (row.position || '') + row.amount)}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell className='date' title={row.date + ' ' + row.time}>
                  {row.date.substring(0, 5)}
                  {row.time ? ` ${row.time}` : ''}
                </TableCell>
                <TableCell className='amount'>{priceFormat(row.amount)}</TableCell>
                <TableCell className='note'>
                  <HighlightedNotes
                    notes={[row.name, row.notes].join("\n").trim()}
                    searchValue={searchValue || ''}
                  />
                </TableCell>
                <TableCell className='code'>{row.code}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                className='pagination'
                rowsPerPageOptions={[20, 50, 100, 150, 200]}
                colSpan={3}
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage='Số hàng trên trang'
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      'aria-label': 'Số hàng trên trang',
                    },
                    native: true,
                  },
                }}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
  );
};

export default TransactionTable;
