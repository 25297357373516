import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _cloneDeep from 'lodash/cloneDeep';
import Typography from '@mui/material/Typography';

import useDebounce from '../../hooks/useDebounce';
import { priceFormat, removeVietnameseTones } from '../../utils/string';
import TransactionTable from './TransactionTable';
import { getAllTransactions } from '../../services/transaction';

export default function Home() {
  const [transactions, setTransactions] = useState(null);
  const [search, setSearch] = useState('');
  const debouncedValue = useDebounce(search, 300);
  const searchValue = removeVietnameseTones(debouncedValue).toLowerCase();

  const [transactionsFiltered, setTransactionsFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sort, setSort] = useState('');
  const [source, setSource] = useState('all');
  // const [amountFrom, setAmountFrom] = useState('');
  // const [amountTo, setAmountTo] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
  };

  const handleSort = (filter) => {
    if (filter.sort) {
      setSort(filter.sort);
    }
    if (filter.source) {
      setSource(filter.source);
    }
    setPage(0);

    const filteredTransactions = filterList({
      data: transactions,
      search: searchValue,
      sort: filter.sort || sort,
      source: filter.source || source,
    });
    setTransactionsFiltered(filteredTransactions);
  };

  const filterList = ({ data, search, sort, source } = {}) => {
    let filteredData = [...(data || [])];

    if (search) {
      // Filter transactions by notes
      filteredData = filteredData.filter(
        (transaction) =>
          (transaction.notes || '').toLowerCase().includes(search) ||
          String(transaction.amount || '').includes(search) ||
          (transaction.name || '').toLowerCase().includes(search)
      );
    }

    if (sort) {
      if (sort === -1 || sort === 1) {
        filteredData = filteredData.sort((a, b) => {
          if (sort === 1) {
            return a.amount - b.amount;
          }
          return b.amount - a.amount;
        });
      } else if (sort === 2) {
        filteredData = filteredData.sort(
          // date: DD/MM/YYYY
          (a, b) => {
            const d1 = new Date(
              a.date.substring(6, 10),
              Number(a.date.substring(3, 5)) - 1,
              a.date.substring(0, 2)
            );
            const d2 = new Date(
              b.date.substring(6, 10),
              Number(b.date.substring(3, 5)) - 1,
              b.date.substring(0, 2)
            );

            return d2.getTime() - d1.getTime();
          }
        );
      } else if (sort === 3) {
        filteredData = filteredData.sort(
          // date: DD/MM/YYYY
          (a, b) => {
            const d1 = new Date(
              a.date.substring(6, 10),
              Number(a.date.substring(3, 5)) - 1,
              a.date.substring(0, 2)
            );
            const d2 = new Date(
              b.date.substring(6, 10),
              Number(b.date.substring(3, 5)) - 1,
              b.date.substring(0, 2)
            );

            return d1.getTime() - d2.getTime();
          }
        );
      }
    }

    if (source && source !== 'all') {
      if (source === 'vcb') {
        filteredData = filteredData.filter(
          (transaction) => !transaction.source || transaction.source === 'vcb'
        );
      } else {
        filteredData = filteredData.filter(
          (transaction) => transaction.source === source
        );
      }
    }

    return _cloneDeep(filteredData);
  };

  const handleSearch = (value) => {
    const filteredTransactions = filterList({
      data: transactions,
      search: value,
      sort,
      source,
    });

    setTransactionsFiltered(filteredTransactions);
  };

  useEffect(() => {
    if (searchValue) {
      handleSearch(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (transactions?.length) {
      return;
    }

    // Load the json file
    if (transactions === null) {
      getAllTransactions().then((data) => {
        setTransactions(data);
        setTransactionsFiltered(data);
      });
    }
  }, [transactions]);

  return (
    <Box sx={{ margin: '0 auto', maxWidth: '1400px' }} padding={2}>
      <Box>
        <Typography variant='h5' align='center'>
          <img src='/8f70378fd495.jpg' height={150} />
          <div>Sao kê tiền gửi đến Ủy ban Trung ương MTTQ Việt Nam</div>
        </Typography>
      </Box>
      <Box>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          paddingTop={2}
        >
          <TextField
            fullWidth
            id='outlined-basic'
            label='Tìm kiếm'
            variant='outlined'
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            placeholder='Tìm kiếm theo nội dung, số tiền, tên người gửi'
          />
        </Box>
        <Box display='flex' flexDirection='row' flexWrap='wrap' gap={2}>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: 59 }}>Sắp Xếp</Box>
            <Box>
              <Select
                value={sort}
                sx={{ width: 100 }}
                onChange={(e) => handleSort({ sort: e.target.value })}
              >
                <MenuItem value={-1}>Tiền Giảm Dần</MenuItem>
                <MenuItem value={1}>Tiền Tăng Dần</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Box sx={{ width: 59 }}>Nguồn</Box>
            <Box>
              <Select
                value={source}
                sx={{ width: 100 }}
                onChange={(e) => handleSort({ source: e.target.value })}
              >
                <MenuItem value={'all'}>Tất Cả</MenuItem>
                <MenuItem value={'vcb'}>Vietcombank</MenuItem>
                <MenuItem value={'vt'}>Vietinbank</MenuItem>
                <MenuItem value={'bidv'}>BIDV</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Box>Kết Quả: {priceFormat(transactionsFiltered?.length || 0)}</Box>
          </Box>

          {/* <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Box>Số Tiền Từ</Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <TextField sx={{ width: 220 }} value={amountFrom} />
              <span>-</span>
              <TextField sx={{ width: 220 }} value={amountTo} />
            </Box>
          </Box> */}
        </Box>
      </Box>

      <TransactionTable
        transactions={transactionsFiltered}
        loading={!transactions?.length}
        searchValue={debouncedValue}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
